import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { AutoLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';

const StyledContent = styled(Content)``;

const Text = styled(AutoLayout)`
  position: relative;
  z-index: 2;

  max-width: 680px;
`;

const Graphic = styled.div`
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 55%;
  width: 70%;

  pointer-events: none;
  user-select: none;

  .gatsby-image-wrapper {
    display: block;
  }

  @media screen and (max-width: 840px) {
    top: 40px;
    width: 588px;
  }
`;

const StyledAboutSection = styled(Section)`
  overflow: hidden;
`;

const AboutSection = () => {
  return (
    <StyledAboutSection>
      <StyledContent gap={15}>
        <h1>About Us</h1>
        <Text direction="column" gap={30}>
          <p className="content-regular">
            The International Racehorse Owners Network (IRON) is formed by key racing stakeholders globally from breeders to stud farms, auction houses to agistment farms and trainers to notable owners.
          </p>

          <p className="content-regular">
            Our overarching objective is to promote the sustainability of the thoroughbred racing industry by making racehorse ownership experience accessible to a younger generation of audiences.
          </p>
        </Text>
        <Graphic>
          <StaticImage
            src="../images/about-graphic-horse.png"
            alt=""
            loading="eager"
            placeholder="none"
            width={1002}
          />
        </Graphic>
      </StyledContent>
    </StyledAboutSection>
  );
};

export default AboutSection;
