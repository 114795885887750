import React from 'react';
import styled from 'styled-components';
import { OffsetAnchor } from '@digigear/elements';

import Content from './Content';
import HorseMarquee from './HorseMarquee';
import MintArea from './MintArea';
import RacehorseTitle from './RacehorseTitle';
import Section from './Section';

const StyledContent = styled(Content)`
  padding-top: 52px;
  max-width: 980px;
`;

const StyledHeroSection = styled(Section)``;

const HeroSection = () => {
  return (
    <>
      <HorseMarquee />
      <StyledHeroSection>
        <StyledContent gap={20}>
          <OffsetAnchor id="mint" />
          <RacehorseTitle />
          <p style={{ marginBottom: 22 }} className="content-regular">
            RacehorseNFT is a collection of unique digital collectibles living on
            the Ethereum blockchain. As an owner of RacehorseNFT, the International
            Racehorse Owners Network (IRON) will grant you a complimentary limited 
            lifetime membership which brings you revolutionary racehorse ownership experience. 
            Your unique RacehorseNFT is also your digital member pass to explore IRON’s 
            incredible privileges including access to racehorse information and activities, 
            members-only benefits, invitation to privileged access to our network of partners
            facilities and digital assets of our racehorses. More perks, benefits and reward
            programme will be unlocked and revealed as our community walk through our roadmap activation.
          </p>
          {/* <p>
            A limited release of 500 Genesis Series RacehorseNFT are reserved for
            whitelisting (Max. 30 per wallet) with the Pre-Sale scheduled on 21 September 2022.
          </p>
          <p>
            For Pre-Sale inquiries please email your contact detail to <a href="mailto:support@ironracing.org">support@ironracing.org</a>
          </p> */}
          {/* <MintArea saleIds={[1, 0]} saleTitles={['Pre-Sale', 'Public Sale']} /> */}
          {/* <MintArea saleIds={[1]} saleTitles={['Pre-Sale']} /> */}
        </StyledContent>
      </StyledHeroSection>
    </>
  );
};

export default HeroSection;
