import styled from 'styled-components';

const GlowBorder = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  border: 2px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;
  filter: drop-shadow(0px 0px 8px #50eaff);
  pointer-events: none;
  user-select: none;
`;

export default GlowBorder;
