import React from 'react';
import styled from 'styled-components';

import { AutoLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';
import Button from './Button';

const Box = styled(AutoLayout)`
  background: rgba(0, 0, 0, 0.3);
  padding: 64px;

  color: ${props => props.theme.palette.primary.main};
`;

const StyledContent = styled(Content)``;

const StyledQuestionsSection = styled(Section)``;

const QuestionsSection = () => {
  return (
    <StyledQuestionsSection>
      <StyledContent gap={15}>
        <Box direction="column" gap={50} alignItems="center">
          <h1>Got a Question?</h1>
          <Button to="/faqs" style={{ width: 176, boxSizing: 'border-box' }}>
            FAQs
          </Button>
        </Box>
      </StyledContent>
    </StyledQuestionsSection>
  );
};

export default QuestionsSection;
