import * as React from 'react';

import { OffsetAnchor } from '@digigear/elements';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import HeroSection from '../components/HeroSection';
import AboutSection from '../components/AboutSection';
import PartnersSection from '../components/PartnersSection';
import FeaturesSection from '../components/FeaturesSection';
import MembershipsSection from '../components/MembershipsSection';
import TiersSection from '../components/TiersSection';
import RoadmapSection from '../components/RoadmapSection';
import ActivationsSection from '../components/ActivationsSection';
import QuestionsSection from '../components/QuestionsSection';
import Content from '../components/Content';
import Section from '../components/Section';

const IndexPage = () => (
  <Layout>
    <Seo title="Racehorse NFT" />
    <HeroSection />

    <OffsetAnchor id="about-us" />
    <AboutSection />

    <OffsetAnchor id="features" />
    <FeaturesSection />

    <OffsetAnchor id="memberships" />
    <MembershipsSection />
    <TiersSection />

    <OffsetAnchor id="roadmap" />
    <RoadmapSection />
    <ActivationsSection />

    <PartnersSection />

    <Section>
      <Content
        style={{
          paddingTop: 32,
          paddingBottom: 0,
          fontSize: 14,
        }}
      >
        (*) Subject to Membership Tiers and the Terms and Conditions
      </Content>
      <Content
        style={{
          paddingTop: 0,
          paddingBottom: 32,
          fontSize: 14,
        }}
      >
        (**) $Carrot is the unit of credit to redeem benefits within the IRON.
      </Content>
    </Section>

    {/*<QuestionsSection />*/}
  </Layout>
);

export default IndexPage;
