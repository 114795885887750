import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { intervalToDuration, isBefore } from 'date-fns';
import { AutoLayout } from '@digigear/elements';

import MintLayout from './MintLayout';

const StyledMintLayout = styled(MintLayout)`
  .time-row {
    gap: 90px;
    margin-bottom: 29px;
  }

  .number {
    font-weight: 800;
    font-size: 96px;
    line-height: 1.45;
  }

  .unit {
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    letter-spacing: 0.1ch;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    .time-row {
      gap: 20px;
      .number {
        font-size: 64px;
      }
    }
  }
`;

const Time = ({ value, unit }) => {
  return (
    <AutoLayout direction="column" alignItems="center">
      <div className="number">{String(value || 0).padStart(2, '0')}</div>
      <div className="unit">{unit}s</div>
    </AutoLayout>
  );
};

const Tba = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 0.15ch;
  margin-top: 20px;
`;

const MintCountdown = ({ date, timeZone, timeZoneName, label }) => {
  const [now, setNow] = useState(() => new Date());

  useEffect(() => {
    const id = setInterval(() => {
      setNow(new Date());
    }, 1000);

    setNow(new Date());

    return () => clearInterval(id);
  }, []);

  const isStarted = date ? isBefore(date, now) : false;
  const duration =
    !date || isStarted
      ? {}
      : intervalToDuration({
          start: now,
          end: date,
        });

  return (
    <StyledMintLayout
      title={
        date ? (isStarted ? 'Mint Beginning Now…' : label + ' Mint In') : label
      }
    >
      <AutoLayout
        direction="row"
        justifyContent="center"
        flexWrap="wrap"
        className="time-row"
      >
        {date ? (
          <>
            <Time value={duration.days} unit="day" />
            <Time value={duration.hours} unit="hour" />
            <Time value={duration.minutes} unit="minute" />
            <Time value={duration.seconds} unit="second" />
          </>
        ) : (
          <Tba>To Be Announced</Tba>
        )}
      </AutoLayout>
    </StyledMintLayout>
  );
};

export default MintCountdown;
