import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { AutoLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';

// Emulate gap with negative margin in parent
// Need this because this is easier to support flex wrap
const Partners = styled.div`
  margin: -10px;

  & > * > * {
    margin: 10px;
  }
`;

const StyledContent = styled(Content)``;

const StyledPartnersSection = styled(Section)``;

const PartnersSection = () => {
  const logoProps = { placeholder: 'dominantColor', width: 220 };
  return (
    <StyledPartnersSection>
      <StyledContent gap={15}>
        <h1>Partners & Sponsors</h1>
        <Partners>
          <AutoLayout direction="row" justifyContent="center" flexWrap="wrap">
            <a href="https://arrowfield.com.au" target="_blank" rel="noopener">
              <StaticImage
                src="../images/sponsor-0.png"
                alt="ARROWFIELD"
                {...logoProps}
              />
            </a>
            <a href="https://inglis.com.au" target="_blank" rel="noopener">
              <StaticImage
                src="../images/sponsor-2.png"
                alt="Inglis"
                {...logoProps}
              />
            </a>
            <a
              href="https://inghaminstitute.org.au/people/john-a-ingham-bba-gaicd"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-9.png"
                alt="Rockingham Thoroughbreds"
                {...logoProps}
              />
            </a>
            <a
              href="https://www.muskokafarm.com.au"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-6.png"
                alt="Muskoka Farm"
                {...logoProps}
              />
            </a>
            <a
              href="http://www.moorebloodstock.com"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-5.png"
                alt="Moore Bloodstock"
                {...logoProps}
              />
            </a>
            <a href="https://cwallerracing.com" target="_blank" rel="noopener">
              <StaticImage
                src="../images/sponsor-1.png"
                alt="Chris Waller Racing"
                {...logoProps}
              />
            </a>
            <a
              href="https://snowdenracing.com.au"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-8.png"
                alt="Snowden Racing"
                {...logoProps}
              />
            </a>
            <a
              href="https://www.johnmooreracing.com.au"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-3.png"
                alt="John Moore Racing"
                {...logoProps}
              />
            </a>
            <a
              href="https://annabelneashamracing.com"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-7.png"
                alt="Annabel Neasham Racing"
                {...logoProps}
              />
            </a>
            <a
              href="https://marknewnhamracing.com"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-4.png"
                alt="Mark Newnham Racing"
                {...logoProps}
              />
            </a>
            <div>
              <StaticImage
                src="../images/sponsor-10.png"
                alt="Boehm Capital"
                {...logoProps}
              />
            </div>
            <a
              href="https://www.qbncapital.com"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-11.png"
                alt="QBN Capital"
                {...logoProps}
              />
            </a>

            <a
              href="https://www.prizmgroup.com"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-12.png"
                alt="Prizm Group"
                {...logoProps}
              />
            </a>

            <a
              href="https://digigear.io"
              target="_blank"
              rel="noopener"
            >
              <StaticImage
                src="../images/sponsor-13.png"
                alt="DigiGear Technology"
                {...logoProps}
              />
            </a>
          </AutoLayout>
        </Partners>
      </StyledContent>
    </StyledPartnersSection>
  );
};

export default PartnersSection;
