import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { BsChevronRight } from 'react-icons/bs';
import AnimateHeight from 'react-animate-height';

import {
  AutoLayout,
  BaseButton,
  OffsetAnchor,
  ResponsiveLayout,
} from '@digigear/elements';

import Section from './Section';
import Content from './Content';
import GlowBorder from './GlowBorder';
import ownershipIcon from '../images/memberships-ownership-icon.svg';
import galleryIcon from '../images/memberships-gallery-icon.svg';
import newsletterIcon from '../images/memberships-newsletter-icon.svg';
import raceDayIcon from '../images/memberships-race-day-icon.svg';
import stableVisitIcon from '../images/memberships-stable-visit-icon.svg';
import weeklyUpdateIcon from '../images/memberships-weekly-update-icon.svg';
import whitelistIcon from '../images/memberships-whitelist-icon.svg';
import winningPhotoIcon from '../images/memberships-winning-photo-icon.svg';

const StyledPrivilege = styled(BaseButton)`
  position: relative;
  padding: 32px;

  text-align: start;
  text-decoration: none;

  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
    cursor: pointer;

    .icon {
      opacity: 1;
    }
  }

  h2 {
    color: ${props => props.theme.palette.primary.main};
  }

  .icon {
    margin-top: 4px;
    svg {
      width: 28px;
      height: 28px;
      transform: scaleX(0.9);
    }

    color: ${props => props.theme.palette.primary.main};
    filter: drop-shadow(0px 0px 8px #50eaff);

    transition: opacity 0.2s, transform 0.2s;
  }

  ${props =>
    !props.mobile &&
    css`
      opacity: ${props => (props.selected ? 1 : 0.3)};
      .icon {
        opacity: ${props => (props.selected ? 1 : 0)};
      }
    `};

  ${props =>
    props.mobile &&
    css`
      .icon {
        transform: rotate(${props => (props.expanded ? '90deg' : '270deg')});
      }
    `}
`;

const Privilege = ({ title, selected, mobile, expanded, id, children }) => {
  return (
    <StyledPrivilege
      selected={selected}
      mobile={mobile}
      expanded={expanded}
      href={id && '#memberships-' + id}
    >
      <AutoLayout direction="column" gap={10}>
        <AutoLayout
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <h2>{title}</h2>
          <div className="icon">
            <BsChevronRight />
          </div>
        </AutoLayout>
        <p className="body-m content-regular">{children}</p>
      </AutoLayout>
      <GlowBorder />
    </StyledPrivilege>
  );
};

const StyledPrivilegeSidebar = styled(AutoLayout)`
  width: 480px;
  min-width: 480px;
  max-width: 480px;
  min-height: 1px;

  .content {
    width: 480px;
  }
`;

const PrivilegeSidebar = ({ pinMode, pinnedTop, contentRef, children }) => {
  const containerRef = useRef();

  const contentStyle =
    pinMode === 'sticky'
      ? {
          position: 'sticky',
          top: pinnedTop,
        }
      : {};

  return (
    <StyledPrivilegeSidebar
      direction="column"
      ref={containerRef}
      justifyContent={pinMode === 'end' ? 'flex-end' : 'flex-start'}
    >
      <AutoLayout
        direction="column"
        gap={20}
        className="content"
        style={contentStyle}
        ref={contentRef}
      >
        {children}
      </AutoLayout>
    </StyledPrivilegeSidebar>
  );
};

const StyledPrivilegeDetail = styled(AutoLayout)`
  & > ${StyledPrivilege} {
    display: none;
  }
  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    & > ${StyledPrivilege} {
      display: flex;
    }
    & > :not(${StyledPrivilege}) {
      ${props =>
        !props.expanded &&
        css`
          display: none;
        `};
    }
  }

  position: relative;

  .anchor {
    position: absolute;
    top: -100px;
  }
`;

const ExpandablePrivilege = ({
  id,
  renderPrivilege,
  children,
  expanded,
  onToggle,
}) => {
  return (
    <StyledPrivilegeDetail
      direction="column"
      gap={20}
      expanded={expanded}
      onClick={onToggle}
    >
      <div className="anchor" id={id} />
      {renderPrivilege({ mobile: true, expanded })}
      <AnimateHeight
        duration={expanded ? 600 : 0}
        height={expanded ? 'auto' : 0}
      >
        {children}
      </AnimateHeight>
    </StyledPrivilegeDetail>
  );
};

const StyledAccess = styled(AutoLayout)`
  padding: 45px;
  border-radius: 5px;

  max-width: 330px;
  min-height: 250px;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    max-width: none;
  }

  box-sizing: border-box;

  background: rgba(0, 0, 0, 0.25);

  .title {
    font-size: 16px;
    line-height: 26px;

    text-align: center;
  }
`;

const Access = ({ svg, children }) => {
  return (
    <StyledAccess
      direction="column"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
      flexShrink={1}
      style={{ flexBasis: 0 }}
    >
      <img src={svg} alt="" width={100} height={100} />
      <div className="content-strong title">{children}</div>
    </StyledAccess>
  );
};

const AccessRow = styled(ResponsiveLayout).attrs({ gap: 20 })`
  align-items: stretch;
`;

const FacilityImage = styled(AutoLayout).attrs({
  direction: 'column',
  gap: 16,
  alignItems: 'center',
})`
  border-radius: 5px;
  padding: 30px;

  background: rgba(0, 0, 0, 0.25);

  font-size: 20px;
  line-height: 26px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;

  .gatsby-image-wrapper {
    border-radius: 5px;
  }
`;

const StyledContent = styled(Content)``;

const StyledMembershipsSection = styled(Section)`
  .not-mobile {
    display: flex;
  }

  .mobile {
    display: none;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    .not-mobile {
      display: none;
    }

    .mobile {
      display: flex;
    }
  }
`;

const MembershipsSection = () => {
  const privileges = [
    {
      id: 'access',
      renderPrivilege: props => (
        <Privilege
          title="Exclusive Access to Racehorse Ownership Experience & Digital Assets"
          {...props}
        >
          Genesis Series RacehorseNFT holders have exclusive access to Genesis
          Series racehorses and their Digital Assets
        </Privilege>
      ),

      body: (
        <AutoLayout direction="column" flexGrow={1} flexShrink={1} gap={20}>
          <AccessRow>
            <Access svg={ownershipIcon}>Digital ownership experience</Access>
            <Access svg={galleryIcon}>Digital assets gallery</Access>
          </AccessRow>
          <AccessRow>
            <Access svg={weeklyUpdateIcon}>
              Regular updates on Genesis Series racehorses in Text, Audio,
              Photo, or Video
            </Access>
            <Access svg={newsletterIcon}>Monthly club newsletter</Access>
          </AccessRow>
          <AccessRow>
            <Access svg={raceDayIcon}>Race day access*</Access>
            <Access svg={stableVisitIcon}>
              Stable visit and attend training session*
            </Access>
          </AccessRow>
          <AccessRow>
            <Access svg={winningPhotoIcon}>
              Winning photos with Genesis Series Racehorses*
            </Access>
            <Access svg={whitelistIcon}>
              Whitelist spot for the Next Series of RacehorseNFT drop*
            </Access>
          </AccessRow>
        </AutoLayout>
      ),
    },
    {
      id: 'facilities',
      renderPrivilege: props => (
        <Privilege title="Global Partners Facilities" {...props}>
          Access our international network of partners facilities and experience
          the best of the world of racing, wherever you go.
        </Privilege>
      ),
      body: (
        <AutoLayout direction="column" flexGrow={1} flexShrink={1} gap={20}>
          <FacilityImage>
            <StaticImage
              src="../images/facilities-0.jpg"
              alt=""
              placeholder="dominantColor"
              width={680}
              height={454}
            />
            <span>Arrowfield Thoroughbred Studs</span>
          </FacilityImage>
          <FacilityImage>
            <StaticImage
              src="../images/facilities-1.jpg"
              alt=""
              placeholder="dominantColor"
              width={680}
              height={454}
            />
            <span>William Inglis & Sons</span>
          </FacilityImage>
          <FacilityImage>
            <StaticImage
              src="../images/facilities-2.jpg"
              alt=""
              placeholder="dominantColor"
              width={680}
              height={454}
            />
            <span>Muskoka Farm</span>
          </FacilityImage>
        </AutoLayout>
      ),
    },
    {
      id: 'events',
      renderPrivilege: props => (
        <Privilege title="Members-Only Event Access*" {...props}>
          Your unique RacehorseNFT is also your digital membership pass to
          register for our exclusive members-only events.
        </Privilege>
      ),
      body: (
        <AutoLayout direction="column" flexGrow={1} flexShrink={1} gap={20}>
          {/* <FacilityImage>
            <StaticImage
              src="../images/events-0.png"
              alt=""
              placeholder="dominantColor"
              width={614}
              height={410}
            />
            <span>Private Jet Experience</span>
          </FacilityImage> */}
          <FacilityImage>
            <StaticImage
              src="../images/events-1.png"
              alt=""
              placeholder="dominantColor"
              width={614}
              height={410}
            />
            <span>Yachting Experience</span>
          </FacilityImage>
          <FacilityImage>
            <StaticImage
              src="../images/events-2.png"
              alt=""
              placeholder="dominantColor"
              width={614}
              height={410}
            />
            <span>Race Day Banquet</span>
          </FacilityImage>
        </AutoLayout>
      ),
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mobileSelectedIndex, setMobileSelectedIndex] = useState(0);
  const [pinMode, setPinMode] = useState(false);
  const parentRef = useRef();
  const contentRef = useRef();
  const pinnedTop = 140;

  useEffect(() => {
    const handleScroll = () => {
      if (parentRef.current && contentRef.current) {
        let selectedIndex = null;
        let isEnd = false;
        const children = Array.from(parentRef.current.children);
        children.forEach((sectionElm, i) => {
          const rect = sectionElm.getBoundingClientRect();
          if (rect.y < pinnedTop) {
            selectedIndex = i;

            if (i === children.length - 1) {
              isEnd =
                rect.y +
                  rect.height -
                  contentRef.current.getBoundingClientRect().height <
                pinnedTop;
            }
          }
        });

        setSelectedIndex(selectedIndex || 0);
        setPinMode(isEnd ? 'end' : 'sticky');
      } else {
        setSelectedIndex(null);
        setPinMode('none');
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useLayoutEffect(() => {
    if (mobileSelectedIndex > 0) {
      document
        .getElementById('privilege-' + mobileSelectedIndex)
        ?.scrollIntoView();
    }
  }, [mobileSelectedIndex]);

  return (
    <StyledMembershipsSection>
      <StyledContent gap={12}>
        <h1>Memberships</h1>
        <AutoLayout direction="row" gap={20} className="not-mobile">
          <PrivilegeSidebar
            pinnedTop={pinnedTop}
            pinMode={pinMode}
            contentRef={contentRef}
          >
            {privileges.map((c, i) =>
              c.renderPrivilege({
                key: c.id,
                id: c.id,
                selected: i === selectedIndex,
              })
            )}
          </PrivilegeSidebar>

          <AutoLayout direction="column" ref={parentRef}>
            {privileges.map((c, i) => (
              <React.Fragment key={c.id}>
                <AutoLayout direction="column">
                  {i !== 0 && <div style={{ height: 200 }} />}
                  <OffsetAnchor id={'memberships-' + c.id} />
                  {c.body}
                </AutoLayout>
              </React.Fragment>
            ))}
          </AutoLayout>
        </AutoLayout>
        <AutoLayout direction="column" gap={20} className="mobile">
          {privileges.map((c, i) => (
            <ExpandablePrivilege
              id={`privilege-` + i}
              key={i}
              renderPrivilege={c.renderPrivilege}
              expanded={i === mobileSelectedIndex}
              onToggle={() => {
                setMobileSelectedIndex(mobileSelectedIndex === i ? -1 : i);
              }}
            >
              {c.body}
            </ExpandablePrivilege>
          ))}
        </AutoLayout>
      </StyledContent>
    </StyledMembershipsSection>
  );
};

export default MembershipsSection;
