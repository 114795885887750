import React from 'react';
import styled from 'styled-components';

import { AutoLayout, ResponsiveLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';
import tickedSvg from '../images/ticked.svg';
import untickedSvg from '../images/unticked.svg';

const StyledRoadmapItem = styled(AutoLayout)`
  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    display: none;
  }

  flex: 1;

  color: ${props => props.theme.palette.primary.main};

  .date {
    max-width: 202px;
    height: 64px;
    text-align: center;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .line {
    align-self: stretch;
  }

  .line-start,
  .line-end {
    flex: 1;
    height: 2px;

    background-color: ${props => props.theme.palette.primary.main};
  }

  .icon img {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 -2px;
  }

  &.first .line-start {
    opacity: 0;
  }

  &.last .line-end {
    opacity: 0;
  }

  .content {
    max-width: 180px;

    font-size: 20px;
    line-height: 29px;
    text-align: center;
    letter-spacing: 2px;
  }
`;

const MobileRoadmapItem = styled(AutoLayout)`
  display: none;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    display: flex;
  }

  &:not(.last) {
    height: 180px;
  }

  color: ${props => props.theme.palette.primary.main};

  .date {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .line {
    align-self: stretch;
  }

  .line-start,
  .line-end {
    width: 2px;

    background-color: ${props => props.theme.palette.primary.main};
  }
  .line-start {
    height: 13px;
  }
  .line-end {
    flex: 1;
  }

  .icon img {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0 -2px;
  }

  &.first .line-start {
    opacity: 0;
  }

  &.last .line-end {
    opacity: 0;
  }

  .content {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 2px;
  }
`;

const RoadmapItem = ({ date, done, children, className }) => {
  const parts = date.split('_');
  return (
    <>
      <StyledRoadmapItem
        direction="column"
        gap={24}
        alignItems="center"
        className={className}
      >
        <div className="date">
          {parts[0]}
          <br />
          {parts[1]}
        </div>
        <AutoLayout direction="row" alignItems="center" className="line">
          <div className="line-start" />
          <div className="icon">
            {done ? (
              <img src={tickedSvg} alt="Done" />
            ) : (
              <img src={untickedSvg} alt="" />
            )}
          </div>
          <div className="line-end" />
        </AutoLayout>
        <div className="content">{children}</div>
      </StyledRoadmapItem>

      <MobileRoadmapItem
        direction="row"
        gap={19}
        alignItems="flex-start"
        className={className}
      >
        <AutoLayout direction="column" alignItems="center" className="line">
          <div className="line-start" />
          <div className="icon">
            {done ? (
              <img src={tickedSvg} alt="Done" />
            ) : (
              <img src={untickedSvg} alt="" />
            )}
          </div>
          <div className="line-end" />
        </AutoLayout>
        <AutoLayout direction="column" gap={5}>
          <div className="date">
            {parts[0]}
            <br />
            {parts[1]}
          </div>
          <div className="content">{children}</div>
        </AutoLayout>
      </MobileRoadmapItem>
    </>
  );
};

const StyledContent = styled(Content)`
  .layout {
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    .layout {
      align-self: center;

      flex-direction: column;
      align-items: flex-start;
    }
  }
`;

const StyledRoadmapSection = styled(Section)``;

const RoadmapSection = () => {
  return (
    <StyledRoadmapSection>
      <StyledContent gap={12}>
        <h1>Roadmap</h1>
        <div className="layout">
          <RoadmapItem date="April 6-7_2021" done className="first">
            Acquisition of Genesis Series Racehorses
          </RoadmapItem>
          <RoadmapItem date="March 26_2022" done>
            Teaser Mint Site Launch
          </RoadmapItem>
          <RoadmapItem date="April 16_2022" done>
            Full Mint Site Launch & Whitelist
          </RoadmapItem>
          <RoadmapItem date="September 21_2022">
            Pre-Sale of Genesis Series RacehorseNFT
          </RoadmapItem>
          <RoadmapItem date="TBA" className="last">
            Public Sale of Genesis Series RacehorseNFT
          </RoadmapItem>
        </div>
      </StyledContent>
    </StyledRoadmapSection>
  );
};

export default RoadmapSection;
