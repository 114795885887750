import React from 'react';
import styled from 'styled-components';

import { AutoLayout, ResponsiveLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';
import nftGif from '../images/nft.gif';
import racehorsesGif from '../images/racehorses.gif';

const NftImage = styled.img`
  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    max-width: 380px;
    max-height: 380px;
    width: auto;
    height: auto;
    max-width: 100%;
  }
`;

const StyledResponsiveLayout = styled(ResponsiveLayout)`
  align-items: flex-start;
  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    align-items: center;
  }
`;

const StyledP = styled.p`
  margin-top: 50px;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    margin-top: 0px;
  }
`;

const StyledContent = styled(Content)``;

const StyledFeaturesSection = styled(Section)`
  p {
    margin-bottom: 30px;
  }

  img {
    border-radius: 5px;
  }
`;

const FeaturesSection = () => {
  return (
    <StyledFeaturesSection>
      <StyledContent gap={100}>
        <StyledResponsiveLayout gap={50}>
          <AutoLayout direction="column" gap={15}>
            <h1>Features</h1>
            <StyledP className="content-regular">
              Genesis Series RacehorseNFT is a collection of 10,000 algorithmically generated unique digital collectibles living on the Ethereum blockchain. Each Genesis Series RacehorseNFT is unique and composed from over 240 possible traits, head gear, race silk, and more. Genesis Series RacehorseNFT owner can access IRON’s members-only area to Design Your Own Racing Silks (DYOS) and experience other member benefits.
            </StyledP>
          </AutoLayout>
          <NftImage src={nftGif} alt="Racehorse NFT" width={380} height={380} />
        </StyledResponsiveLayout>

        <ResponsiveLayout gap={50} reversed>
          <AutoLayout direction="column" gap={15}>
            <p className="content-regular">
              Your unique Genesis Series RacehorseNFT is also your digital member pass to IRON in accessing racehorse ownership experiences through our 7 Genesis Series Racehorses based in Australia. They are pampered and trained by some of the industry best trainers and horsemen with access to the best possible facilities that are available. You are entitled to member access on their latest information including their training reports, race plans and preparations, pre-race and post-race updates and more. 
              Our reward programme will be unlocked and revealed to allow you to earn $Carrot** and redeem rewards as our community walk through the roadmap activation.
            </p>
          </AutoLayout>
          <NftImage
            src={racehorsesGif}
            alt="Racehorse NFT"
            width={380}
            height={380}
          />
        </ResponsiveLayout>
      </StyledContent>
    </StyledFeaturesSection>
  );
};

export default FeaturesSection;
