import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import { AutoLayout } from '@digigear/elements';

const width = 280;

const nftProps = {
  alt: '',
  placeholder: 'blurred',
  width: width,
  height: width,
};

const StyledHorseMarquee = styled(AutoLayout)`
  width: 100vw;
  overflow: hidden;
  .gatsby-image-wrapper {
    width: ${width}px;
    height: ${width}px;
    margin-right: 20px;
    border-radius: 5px;
  }
`;

const HorseMarquee = () => {
  const speed = 0.25; // image width / s

  const first = [
    <StaticImage src="../images/NFTs/0.png" {...nftProps} key={0} />,
    <StaticImage src="../images/NFTs/1.png" {...nftProps} key={1} />,
    <StaticImage src="../images/NFTs/2.png" {...nftProps} key={2} />,
    <StaticImage src="../images/NFTs/3.png" {...nftProps} key={3} />,
    <StaticImage src="../images/NFTs/4.png" {...nftProps} key={4} />,
    <StaticImage src="../images/NFTs/5.png" {...nftProps} key={5} />,
    <StaticImage src="../images/NFTs/6.png" {...nftProps} key={6} />,
    <StaticImage src="../images/NFTs/7.png" {...nftProps} key={7} />,
    <StaticImage src="../images/NFTs/8.png" {...nftProps} key={8} />,
    <StaticImage src="../images/NFTs/9.png" {...nftProps} key={9} />,
    <StaticImage src="../images/NFTs/10.png" {...nftProps} key={10} />,
    <StaticImage src="../images/NFTs/11.png" {...nftProps} key={11} />,
    <StaticImage src="../images/NFTs/12.png" {...nftProps} key={12} />,
    <StaticImage src="../images/NFTs/13.png" {...nftProps} key={13} />,
    <StaticImage src="../images/NFTs/14.png" {...nftProps} key={14} />,
    <StaticImage src="../images/NFTs/15.png" {...nftProps} key={15} />,
    <StaticImage src="../images/NFTs/16.png" {...nftProps} key={16} />,
    <StaticImage src="../images/NFTs/17.png" {...nftProps} key={17} />,
    <StaticImage src="../images/NFTs/18.png" {...nftProps} key={18} />,
    <StaticImage src="../images/NFTs/19.png" {...nftProps} key={19} />,
    <StaticImage src="../images/NFTs/20.png" {...nftProps} key={20} />,
    <StaticImage src="../images/NFTs/21.png" {...nftProps} key={21} />,
    <StaticImage src="../images/NFTs/22.png" {...nftProps} key={22} />,
    <StaticImage src="../images/NFTs/23.png" {...nftProps} key={23} />,
    <StaticImage src="../images/NFTs/24.png" {...nftProps} key={24} />,
    <StaticImage src="../images/NFTs/25.png" {...nftProps} key={25} />,
    <StaticImage src="../images/NFTs/26.png" {...nftProps} key={26} />,
    <StaticImage src="../images/NFTs/27.png" {...nftProps} key={27} />,
    <StaticImage src="../images/NFTs/28.png" {...nftProps} key={28} />,
  ];
  const second = [
    <StaticImage src="../images/NFTs/29.png" {...nftProps} key={29} />,
    <StaticImage src="../images/NFTs/30.png" {...nftProps} key={30} />,
    <StaticImage src="../images/NFTs/31.png" {...nftProps} key={31} />,
    <StaticImage src="../images/NFTs/32.png" {...nftProps} key={32} />,
    <StaticImage src="../images/NFTs/33.png" {...nftProps} key={33} />,
    <StaticImage src="../images/NFTs/34.png" {...nftProps} key={34} />,
    <StaticImage src="../images/NFTs/35.png" {...nftProps} key={35} />,
    <StaticImage src="../images/NFTs/36.png" {...nftProps} key={36} />,
    <StaticImage src="../images/NFTs/37.png" {...nftProps} key={37} />,
    <StaticImage src="../images/NFTs/38.png" {...nftProps} key={38} />,
    <StaticImage src="../images/NFTs/39.png" {...nftProps} key={39} />,
    <StaticImage src="../images/NFTs/40.png" {...nftProps} key={40} />,
    <StaticImage src="../images/NFTs/41.png" {...nftProps} key={41} />,
    <StaticImage src="../images/NFTs/42.png" {...nftProps} key={42} />,
    <StaticImage src="../images/NFTs/43.png" {...nftProps} key={43} />,
    <StaticImage src="../images/NFTs/44.png" {...nftProps} key={44} />,
    <StaticImage src="../images/NFTs/45.png" {...nftProps} key={45} />,
    <StaticImage src="../images/NFTs/46.png" {...nftProps} key={46} />,
    <StaticImage src="../images/NFTs/47.png" {...nftProps} key={47} />,
    <StaticImage src="../images/NFTs/48.png" {...nftProps} key={48} />,
    <StaticImage src="../images/NFTs/49.png" {...nftProps} key={49} />,
    <StaticImage src="../images/NFTs/50.png" {...nftProps} key={50} />,
    <StaticImage src="../images/NFTs/51.png" {...nftProps} key={51} />,
    <StaticImage src="../images/NFTs/52.png" {...nftProps} key={52} />,
    <StaticImage src="../images/NFTs/53.png" {...nftProps} key={53} />,
    <StaticImage src="../images/NFTs/54.png" {...nftProps} key={54} />,
    <StaticImage src="../images/NFTs/55.png" {...nftProps} key={55} />,
  ];

  const [startedAt, setStartedAt] = useState(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (!startedAt) {
      requestAnimationFrame(setStartedAt);
      return;
    }

    let id;

    const action = timestamp => {
      setOffset(((timestamp - startedAt) / 1000) * speed);

      id = requestAnimationFrame(action);
    };

    id = requestAnimationFrame(action);

    return () => id && cancelAnimationFrame(id);
  }, [startedAt]);

  return (
    <StyledHorseMarquee
      direction="column"
      gap={20}
      alignItems="flex-start"
      firstCount={first.length}
      secondCount={second.length}
    >
      <AutoLayout
        direction="row"
        style={{
          transform: `translateX(-${
            ((offset % first.length) / first.length) * 50
          }%)`,
        }}
      >
        {/* Fragment to make sure key prop is unique */}
        <>{first}</>
        <>{first}</>
      </AutoLayout>
      <AutoLayout
        direction="row"
        style={{
          transform: `translateX(${
            (((offset % second.length) - second.length) / second.length) * 50
          }%)`,
        }}
      >
        <>{second}</>
        <>{second}</>
      </AutoLayout>
    </StyledHorseMarquee>
  );
};

export default HorseMarquee;
