import React from 'react';
import styled from 'styled-components';

import { AutoLayout } from '@digigear/elements';

import Section from './Section';
import Content from './Content';

const StyledActivationItem = styled(AutoLayout)`
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;
  padding: 21px 30px;

  h2 {
    font-size: 22px;
    line-height: 32px;
    font-weight: 700;

    color: ${props => props.theme.palette.primary.main};
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  a {
    text-decoration: underline;
  }
`;

const ActivationItem = ({ title, children }) => {
  return (
    <StyledActivationItem direction="column" gap={15}>
      <h2>{title}</h2>
      <p className="content-regular">{children}</p>
    </StyledActivationItem>
  );
};

const StyledContent = styled(Content)``;

const StyledActivationsSection = styled(Section)``;

const ActivationsSection = () => {
  return (
    <StyledActivationsSection>
      <StyledContent gap={15}>
        <h1>Activations</h1>
        <p className="content-regular">
          We have set up some milestones for ourselves. Once we hit a target sell through percentage, we will begin to work on realising the stated goals.
        </p>
        <ActivationItem title="20% Sold">
          100 Limited Edition “Winning Moment of World First NFT Racehorse” are airdropped to random Genesis Series RacehorseNFT owners
        </ActivationItem>
        <ActivationItem title="40% Sold">
          Member-Exclusive RacehorseNFT Merchandise Store gets unlocked, featuring Limited Edition tees, caps, jackets, gilets, and other goodies
        </ActivationItem>
        <ActivationItem title="60% Sold">
          1 x $Carrots** airdropped to all Genesis Series RacehorseNFT owners upon activation of our reward programme
        </ActivationItem>
        <ActivationItem title="80% Sold">
          We unlock the next global destination to acquire and race Racehorses outside of Australia
        </ActivationItem>
        <ActivationItem title="100% Sold">
          We start building our reward programme to allow you to earn $Carrott** and redeem rewards
        </ActivationItem>
      </StyledContent>
    </StyledActivationsSection>
  );
};

export default ActivationsSection;
