import React from 'react';
import styled from 'styled-components';
import { AutoLayout } from '@digigear/elements';

const StyledMintLayout = styled(AutoLayout)`
  position: relative;

  filter: drop-shadow(0px 0px 4px #50eaff);

  margin-top: 18px;
  padding-bottom: 18px;

  border: 2px solid ${props => props.theme.palette.primary.main};
  border-top: none;

  color: ${props => props.theme.palette.primary.main};

  .top {
    position: relative;
    top: -17px;

    .line {
      height: 2px;
      background: ${props => props.theme.palette.primary.main};
      flex: 1;
    }

    .label {
      text-transform: uppercase;
      font-size: 36px;
      font-weight: 700;
      line-height: 36px;
    }
  }

  .content {
    margin-left: 18px;
    margin-right: 18px;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    .top .label {
      font-size: 24px;
    }
  }
`;

/**
 * @param {{
 *  title?: string
 *  children?: any
 * }}
 */
const MintLayout = ({ title, children, className, style }) => {
  return (
    <StyledMintLayout direction="column" className={className} style={style}>
      <AutoLayout direction="row" gap={20} alignItems="center" className="top">
        <div className="line" />
        <div className="label">{title}</div>
        <div className="line" />
      </AutoLayout>
      <div className="content">{children}</div>
    </StyledMintLayout>
  );
};

export default MintLayout;
