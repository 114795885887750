import React, { useState } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import styled from 'styled-components';
import { AutoLayout } from '@digigear/elements';
import { useWeb3React } from '@web3-react/core';

import { useCurrentSaleInfoQuery, useMint } from '../web3/minting';
import { CurrentSaleInfo, SaleInfo } from '../web3/sale';
import ButtonBase from './Button';
import Input from './Input';
import MintCountdown from './MintCountdown';
import MintLayout from './MintLayout';
import WalletButton from './WalletButton';

const StyledLoading = styled.span`
  display: inline-block;
  line-height: 16px;
  margin-left: 8px;

  svg {
    display: block;
    width: 16px;
    height: 16px;
    animation: rota 1s linear infinite;
  }

  @keyframes rota {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loading = () => {
  return (
    <StyledLoading>
      <AiOutlineLoading />
    </StyledLoading>
  );
};

const Button = styled(ButtonBase)`
  padding: 21px 32px;
  font-size: 24px;
  max-width: 240px;
  margin: 0 auto;
`;

const Label = styled.label`
  min-width: 150px;
`;

const MintArea = ({
  saleIds,
  saleTitles,
}: {
  saleIds: number[];
  saleTitles: string[];
}) => {
  const { active } = useWeb3React();
  const [count, setCount] = useState(1);

  const { data: sales } = useCurrentSaleInfoQuery(saleIds);

  const minting = useMint();

  const onMint = (sale: SaleInfo) => {
    minting.mutate({ sale, tokenId: 0, count });
  };

  return (
    <AutoLayout direction="column" gap={30}>
      {!sales && (
        <AutoLayout direction="row" justifyContent="center">
          Checking mint status…
        </AutoLayout>
      )}
      {sales?.map((sale, i) => {
        switch (sale.status) {
          case 'countdown':
            return (
              <MintCountdown
                key={i}
                label={saleTitles[i]}
                date={sale.beginAt}
                timeZone="Asia/Hong_Kong"
                timeZoneName="HKT"
              />
            );

          case 'on-sale':
            let content;
            if (active && sale.isWhitelist && !sale.eligibilityInfo) {
              content = (
                <AutoLayout direction="row" justifyContent="space-between">
                  Sorry you are not in the Whitelist. Please go to our Telegram
                  for more information.
                  <Button
                    href="https://t.me/+1vdQpuLwFlhhZTA0"
                    target="_blank"
                    rel="noopener"
                    style={{ textTransform: 'none', fontSize: 18 }}
                  >
                    Join our Telegram Channel
                  </Button>
                </AutoLayout>
              );
            } else if (active) {
              const price = sale.sessions[0].cryptoPrice;
              content = (
                <AutoLayout direction="row" justifyContent="space-between">
                  <AutoLayout direction="column" flex={1} gap={10}>
                    <AutoLayout direction="row">
                      <Label>Mint price:</Label>
                      <div>
                        {price.formattedAmount} {price.currency}
                      </div>
                    </AutoLayout>
                    <AutoLayout direction="row">
                      <Label>No. of tokens:</Label>
                      <div>
                        <Input
                          type="number"
                          value={count}
                          onChange={e =>
                            setCount(parseInt(e.target.value, 10) || 1)
                          }
                          min={1}
                          max={sale.sessions[0].quotaRemaining}
                        />
                      </div>
                    </AutoLayout>
                  </AutoLayout>
                  <div style={{ flex: 1 }}></div>
                  {minting.isLoading ? (
                    <Button disabled>
                      <Loading />
                    </Button>
                  ) : (
                    <Button onClick={() => onMint(sale)}>Mint</Button>
                  )}
                </AutoLayout>
              );
            } else {
              content = (
                <AutoLayout direction="row" justifyContent="space-between">
                  <div style={{ flex: 1 }}>
                    {sale.isWhitelist ? (
                      <>
                        To see whether you are eligible to join our pre-sale,
                        please connect your wallet.
                      </>
                    ) : (
                      <>Connect wallet to mint now!</>
                    )}
                  </div>
                  <WalletButton />
                </AutoLayout>
              );
            }

            return (
              <MintLayout key={i} title={`${saleTitles[i]} is live`}>
                {content}
              </MintLayout>
            );

          case 'sold-out':
            return (
              <MintLayout key={i} title={saleTitles[i]}>
                Sold out
              </MintLayout>
            );

          case 'closed':
            return (
              <MintLayout key={i} title={saleTitles[i]}>
                Closed
              </MintLayout>
            );

          default:
            return <></>;
        }
      })}
    </AutoLayout>
  );
};

export default MintArea;
